import React, { useEffect, useState } from "react"
import { css } from "@emotion/core"
import { connect, useDispatch } from "react-redux"
import { navigate } from "gatsby"
import { useIntl } from "react-intl"
import uuid from "react-uuid"

import {
  LayoutQuoting,
  Title,
  SEO,
  SectionCenter,
  SecondaryButton,
  PrimaryButton,
  TravellersCard,
  AddPerson,
  GoBack,
} from "../components"
import {
  getCurrentPath,
  setTravellers,
  getConditions,
  famRateAction,
} from "../redux/actions"
import { validTravelPlan, validTravellers, isFamRate } from "../utils/helpers"

const GetQuoteTravelers = ({
  location,
  getCurrentPath,
  travellers,
  travelPlan,
  setTravellers,
  canProceedFromUI,
}) => {
  const intl = useIntl()
  const [isValidTravelPlan, setIsValidTravelPlan] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    getCurrentPath(location.pathname)
    setIsValidTravelPlan(validTravelPlan(travelPlan))
  }, [getCurrentPath, location.pathname, travelPlan])

  const onAddTraveller = () => {
    dispatch(
      setTravellers({
        first_name: "",
        last_name: "",
        birth_date: "",
        relationship_status: "",
        gender: "",
        is60yrsOrOlder: "",
        id: uuid(),
        options: [{ code: "include-pre-existing-coverage" }],
        mq: {
          code: "QU-VMQ-1",
          questions: [
            {
              code: "QT-VIS-1",
              answers: [
                {
                  code: "q1a1",
                  value: -1,
                },
                {
                  code: "q1a2",
                  value: -1,
                },
                {
                  code: "q1a3",
                  value: -1,
                },
                {
                  code: "q1a4",
                  value: -1,
                },
              ],
            },
            {
              code: "QT-VIS-2",
              answers: [
                {
                  code: "q2a1",
                  value: -1,
                },
                {
                  code: "q2a2",
                  value: -1,
                },
                {
                  code: "q2a3",
                  value: -1,
                },
                {
                  code: "q2a4",
                  value: -1,
                },
                {
                  code: "q2a5",
                  value: -1,
                },
                {
                  code: "q2a6",
                  value: -1,
                },
                {
                  code: "q2a7",
                  value: -1,
                },
                {
                  code: "q2a8",
                  value: -1,
                },
                {
                  code: "q2a9",
                  value: -1,
                },
              ],
            },
            {
              code: "QT-VIS-3",
              answers: [
                {
                  code: "q3a1",
                  value: -1,
                },
                {
                  code: "q3a2",
                  value: -1,
                },
              ],
            },
          ],
        },
      })
    )
  }

  const onProceedToNextPage = () => {
    if (!validTravellers(travellers)) return
    dispatch(getConditions(travellers))
    dispatch(famRateAction(isFamRate(travellers)))
    let mq = false
    for (const traveller of travellers) {
      if (traveller.is60yrsOrOlder == "y") {
        mq = true
      }
    }

    mq ? navigate("/get-quote-travellers-mq") : navigate("/get-quote-policies")
  }

  const onGoBackToPrevPage = () => {
    navigate("/get-quote-details")
  }

  return (
    <LayoutQuoting>
      <SEO title="Get my quote - travellers" />
      <section
        css={css`
          padding: 5rem 0;
        `}
      >
        {isValidTravelPlan ? (
          <>
            <Title
              title={intl.formatMessage({ id: "travellers.title" })}
              fontColor="var(--clr-primary-300)"
            />
            <SectionCenter>
              <div
                css={css`
                  width: 100%;
                  display: grid;
                  row-gap: 2.5rem;
                  margin: 0 auto 4rem auto;
                `}
              >
                {travellers.map(traveller => (
                  <TravellersCard traveller={traveller} key={traveller.id} />
                ))}
                <AddPerson onClick={onAddTraveller} />
              </div>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}
              >
                <PrimaryButton
                  textSize="0.875rem"
                  textTransform="capitalize"
                  maxWidth="none"
                  margin="0 4rem 0 0"
                  disabled={!canProceedFromUI}
                  onClick={onProceedToNextPage}
                >
                  {intl.formatMessage({ id: "btn.continue" })}
                </PrimaryButton>
                <SecondaryButton
                  buttonColor="var(--clr-neutral-400)"
                  textSize="0.875rem"
                  margin="none"
                  onClick={onGoBackToPrevPage}
                >
                  {intl.formatMessage({ id: "btn.goback" })}
                </SecondaryButton>
              </div>
            </SectionCenter>
          </>
        ) : (
          <GoBack
            backToLink="/get-quote-details"
            notFilledOut={intl.formatMessage({ id: "redirect.missingItem3" })}
          />
        )}
      </section>
    </LayoutQuoting>
  )
}

const mapStateToProps = ({ travellers, canProceedFromUI, travelPlan }) => ({
  travellers,
  canProceedFromUI,
  travelPlan,
})

export default connect(mapStateToProps, {
  getCurrentPath,
  setTravellers,
})(GetQuoteTravelers)
